import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from '@auth0/auth0-react';
import {
  callbackUrl,
  authClientId,
  authDomain,
  audience,
} from './constants/configs';
import { ThemeProvider } from '@emotion/react';
// eslint-disable-next-line no-unused-vars
import { lightTheme, darkTheme } from './constants/themes';
ReactDOM.render(
  <Auth0Provider
    domain={authDomain}
    clientId={authClientId}
    redirectUri={callbackUrl}
    audience={audience}
  >
    <React.StrictMode>
      <ThemeProvider theme={darkTheme}>
        <App />
      </ThemeProvider>
    </React.StrictMode>
  </Auth0Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
