const HASURA_GRAPHQL_ENGINE_HOSTNAME = 'kurrip.hasura.app';

const scheme = (proto) => {
  return window.location.protocol === 'https:' ? `${proto}s` : proto;
};

export const GRAPHQL_URL = `${scheme(
  'http'
)}://${HASURA_GRAPHQL_ENGINE_HOSTNAME}/v1/graphql`;

export const REALTIME_GRAPHQL_URL = `${scheme(
  'ws'
)}://${HASURA_GRAPHQL_ENGINE_HOSTNAME}/v1/graphql`;

export const authClientId = process.env.REACT_APP_AUTH_CLIENT_ID;
export const authDomain = process.env.REACT_APP_AUTH_DOMAIN;
export const callbackUrl = window.location.origin;
export const audience = process.env.REACT_APP_AUDIENCE;
